<template>
  <div>
    <DashboardAppDrawer />
    <v-main>
      <keep-alive include="Panels">
        <router-view :is-loading-project="isInitializing" />
      </keep-alive>
    </v-main>

    <!-- <EaseFooter v-if="!$route.meta.hideFooter" /> -->
  </div>
</template>

<script>
// import EaseFooter from '@/components/bar/EaseFooter'
import DashboardAppDrawer from '@/components/bar/DashboardAppDrawer'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Dashboard',

  components: {
    DashboardAppDrawer
    // EaseFooter
  },

  data: () => ({
    isInitializing: false
  }),

  computed: {
    ...mapState({
      grafanaDatasourceId: (state) => state.panels.grafanaDatasourceId,
      isLoadingDatasourceId: function(state) {
        return state.panels.isLoadingDatasourceId === this.project.grafanaId
      }
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route) || {}
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      this.isInitializing = true
      return this.fetchProject()
        .then(() => {
          // set panel form influxBucketName/datasourceId when fetchProject
          this.$store.dispatch('panels/form/setFormData', {
            datasource: this.project.influxBucketName
          })

          return Promise.all([
            this.fetchDatasourceId(),
            this.fetchMeasurements()
          ])
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', { content: error })
        })
        .finally(() => {
          this.isInitializing = false
        })
    },
    fetchProject() {
      if (this.project.influxBucketName) {
        return Promise.resolve()
      }
      return this.$store.dispatch('projects/fetchProject', {
        projectId: this.$route.params.projectId
      })
    },
    fetchDatasourceId() {
      if (!this.project.grafanaId || this.isLoadingDatasourceId) {
        return Promise.resolve()
      }

      return this.$store.dispatch('panels/fetchGrafanaDatasourceId', {
        grafanaId: this.project.grafanaId,
        projectId: (!this.project.isOwner && this.project.uuid) || null
      })
    },
    fetchMeasurements() {
      return this.$store.dispatch('panels/form/fetchMeasurements', {
        project: this.project
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
