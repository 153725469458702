<template>
  <v-navigation-drawer class="bg-ease" app width="250px">
    <v-row align="center" style="height: 70%;">
      <!-- dashboard settings -->
      <v-list-item>
        <v-list-item-content class="py-0" style="height: 48px;">
          <UserAvatar v-if="userName">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" dark text tile large height="100%" v-on="on">
                <v-icon left>
                  mdi-account-circle
                </v-icon>
                {{ userName }}
              </v-btn>
            </template>
          </UserAvatar>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content class="py-0" style="height: 48px;">
          <TranslationMenu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" dark text tile large height="100%" v-on="on">
                <v-icon>
                  mdi-translate
                </v-icon>
              </v-btn>
            </template>
          </TranslationMenu>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-col cols="auto" style="max-width: 250px;">
            <TimezoneSelect
              v-model="timezone"
              dark
              prepend-inner-icon="mdi-earth"
            />
          </v-col>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-col cols="auto" style="max-width: 250px;">
            <TimeRangeSelect
              dark
            />
          </v-col>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content class="py-0" style="height: 48px;">
          <v-col cols="auto" style="height: 100%;">
            <v-btn
              class="font-weight-black"
              :to="toBack"
              text
              exact
              color="white"
              style="height: 100%; font-size: 1.375rem;"
            >
              <v-icon>mdi-undo-variant</v-icon>
              <span v-if="dashboardTitle" class="ml-4">{{
                dashboardTitle
              }}</span>
            </v-btn>
          </v-col>
        </v-list-item-content>
      </v-list-item>
      <!-- go back -->
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import TimezoneSelect from '@/components/TimezoneSelect'
import TranslationMenu from '@/components/TranslationMenu'
import TimeRangeSelect from '@/components/TimeRangeSelect/TimeRangeSelect'

import { mapGetters } from 'vuex'

export default {
  name: 'DashboardAppDrawer',

  components: {
    UserAvatar,
    TimezoneSelect,
    TranslationMenu,
    TimeRangeSelect
  },

  computed: {
    ...mapGetters({
      userName: 'user/userName',
      getDashboardByRoute: 'dashboards/getDashboardByRoute'
    }),

    dashboard() {
      return this.getDashboardByRoute(this.$route) || {}
    },
    dashboardTitle() {
      return this.dashboard?.title
    },
    toBack() {
      if (this?.$route?.meta?.toPanels) {
        return {
          name: 'Panels',
          params: this.$route.params
        }
      }

      return {
        name: 'Dashboards',
        params: this.$route.params
      }
    },

    timezone: {
      get() {
        return this?.$route?.query?.timezone || 'utc'
      },
      set(newVal) {
        if (newVal === this?.$route?.query?.timezone) return

        const { name = '', params = {}, query = {} } = this.$route || {}

        this.$router.replace({
          name,
          params,
          query: {
            ...query,
            timezone: newVal
          }
        })
      }
    }
  },

  created() {
    this.fetchUser() // for device token
  },

  methods: {
    fetchUser() {
      return this.$store.dispatch('user/fetchUser')
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-app-bar {
  ::v-deep .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
